<template lang="pug">
span
  v-btn(
    @click="startMonitoring"
    :disabled="processing"
    data-test="results-monitoring-start"
    v-if="!monitoringTime"
    color="primary"
  ) Start monitoring 
  v-btn(
    @click="stopMonitoring"
    data-test="results-monitoring-stop"
    v-if="monitoringTime"
    :disabled="processing"
    color="warning"
  ) Stop monitoring
  p(v-if="monitoringTime && monitoringTextTime.length") {{ monitoringTextTime }} to end monitoring
</template>

<script>
import ResultsMonitoring from "../core/models/resultsMonitoring"

export default {
  inject: ['svc'],

  data: () => ({
    monitoring: false,
    processing: false,
    ws: null,
    monitoringTime: null,
    monitoringTextTime: '',
    monitoringTimeUpdater: null
  }),

  async created() {
  },

  async mounted() {
    await this.initWS()
    this.updateTime()
    this.startUpdater()

  },

  watch: {
    monitoringTime(value) {
      this.$emit('monitoringStatusChanged', !!value)
    }
  },

  computed: {
    isMonitoringTimeNan() {
      return this.monitoringTextTime.includes('NaN')
    }
  },

  methods: {
    async loadMonitoringStatus() {
      try {
        // this.processing = true
        let res=  await this.svc.monitoringStatus()
        this.monitoring = res.is_enabled
        // this.monitoring = false
        // this.$notify({type: 'success', text: 'Monitoring started'})
      } catch (error) {
      }
      finally {
        // this.processing = false
      }
    },

    async startMonitoring() {
      try {
        this.processing = true
        await this.svc.startMonitoring()
        this.startUpdater()
        this.monitoring = true
        this.$notify({type: 'success', text: 'Monitoring started'})
      } catch (error) {
      }
      finally {
        this.processing = false
      }
    },

    async stopMonitoring() {
      try {
        this.processing = true
        await this.svc.stopMonitoring()
        this.monitoring = false
        this.$notify({type: 'success', text: 'Monitoring stoped'})
        this.stopUpdater()
      } catch (error) {
      }
      finally {
        this.processing = false
      }
    },

    async initWS() {
      let tokens
      tokens = await this.$auth.getTokensFromRepoOrRefresh()
      this.ws = new ResultsMonitoring(tokens.access)
      this.ws.startListen(this.updateMonitoring)

      this.ws.ws.onopen = event => {
        this.loadMonitoringStatus()

        return Promise.resolve()

      }
    },

    updateMonitoring(msg) {
      this.updateTime(msg.ttl)
    },

    updateTime(time) {
      if(!time) {
        this.monitoringTime = null
        this.stopUpdater()
        return
      }
      this.monitoringTime = time;
    },
    convertTime() {
      this.updateLocalTimer();

      let minutes = Math.floor(this.monitoringTime / 60);
      let seconds = this.monitoringTime % 60;

      if (minutes >= 1) {
        this.monitoringTextTime = `${minutes.toString()} minutes`
      }
      if (minutes === 1) {
        this.monitoringTextTime = `${minutes.toString()} minute`
      }
      if (minutes < 1 && seconds) {
        this.monitoringTextTime = 'A few seconds'
      }
    },
    updateLocalTimer() {
      if (this.monitoringTime >= 1) {
        this.monitoringTime -= 1;
      } else {
        this.monitoringTime = null;
      }
    },
    startUpdater() {
      this.monitoringTimeUpdater = setInterval(this.convertTime, 1000)
    },
    stopUpdater() {
      if (this.monitoringTimeUpdater) {
        clearInterval(this.monitoringTimeUpdater)
        this.monitoringTimeUpdater = null
      }
    }
  },

  beforeDestroy() {
    this.stopUpdater()
    this.ws.close()
  }
}
</script>